import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo2"
import Hero from "../components/Hero";

import Translations from '../Translations';

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="container">
      <section className="white padding-medium margin-none">
        <Hero heading={Translations.notFound} body={Translations.notFoundBody} />
        <p className="text-center mt-5">
          <a className="btn btn-primary" href="/">{Translations.toStartPage}</a>
        </p>
      </section>
    </div>
  </Layout>
)

export default NotFoundPage
